<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M7.00457 6.33325H17.0046L11.9946 12.6333L7.00457 6.33325ZM4.25457 5.94325C6.27457 8.53325 10.0046 13.3333 10.0046 13.3333V19.3333C10.0046 19.8833 10.4546 20.3333 11.0046 20.3333H13.0046C13.5546 20.3333 14.0046 19.8833 14.0046 19.3333V13.3333C14.0046 13.3333 17.7246 8.53325 19.7446 5.94325C20.2546 5.28325 19.7846 4.33325 18.9546 4.33325H5.04457C4.21457 4.33325 3.74457 5.28325 4.25457 5.94325Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcFunnel',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
